body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.p1{
    padding: 1rem;
}
.h100{
    height: 100%;
}
.w100{
    width: 100%;
}

.mes-pagado{
    background: #81c784;
}
.mes-pendiente{
    background: #e57373;
}
.mes-abonado{
    background: #ffd54f;
}
.mes-no-aplica{
    background: #e8eaf6;
}
.text-error {
    color: #f00;
}
.text-success {
    color: #81c784;
}
.cursor-pointer {
    cursor: pointer;
}
.celda-link {
    box-sizing: border-box;
}
.decoration-none {
    text-decoration: none;
}
.celda-link:hover {
    background: #eee;
}
.m-left-1 {
    padding-left: 1rem;
}
.flex-dir-column {
    -webkit-flex-direction: column;
            flex-direction: column
}
.icono-mes-pagado{
    color: #81c784;
}
.icono-mes-pendiente{
    color: #e57373;
}
.icono-mes-abonado{
    color: #ffd54f;
}
.icono-mes-no-aplica{
    color: #e8eaf6;
}
