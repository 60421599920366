.icono-mes-pagado{
    color: #81c784;
}
.icono-mes-pendiente{
    color: #e57373;
}
.icono-mes-abonado{
    color: #ffd54f;
}
.icono-mes-no-aplica{
    color: #e8eaf6;
}