.p1{
    padding: 1rem;
}
.h100{
    height: 100%;
}
.w100{
    width: 100%;
}

.mes-pagado{
    background: #81c784;
}
.mes-pendiente{
    background: #e57373;
}
.mes-abonado{
    background: #ffd54f;
}
.mes-no-aplica{
    background: #e8eaf6;
}
.text-error {
    color: #f00;
}
.text-success {
    color: #81c784;
}
.cursor-pointer {
    cursor: pointer;
}
.celda-link {
    box-sizing: border-box;
}
.decoration-none {
    text-decoration: none;
}
.celda-link:hover {
    background: #eee;
}
.m-left-1 {
    padding-left: 1rem;
}
.flex-dir-column {
    flex-direction: column
}